import React from 'react'
import { types, applySnapshot } from "mobx-state-tree";
import Account from './Account';
import Alert from './Alert';
import Forms from './Forms';
import Form from './Form';
import Website from './Website';

const RootStore = types.model({
    websites: types.optional(types.array(Website),[]),
    account: types.optional(Account,{}),
    alert:types.optional(Alert,{
        type:"success"
    }),
    forms:types.optional(Forms,{}),
    form:types.optional(Form,{})
}).actions((self) => {
    function resetFormStore(){
        applySnapshot(self.form,{})
    }

    function setWebsites(data){
        applySnapshot(self.websites,data);
    }

    return {
        resetFormStore,
        setWebsites
    }
})

const store = RootStore.create({
    websites:[],
    account: {},
    alert:{
        type:"success"
    },
    forms:{},
    form:{}
});

const storesContext = React.createContext(store)

export default storesContext;

export {
    store
}