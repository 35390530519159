import React, {useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import useStores from 'states/hooks/useStores';
import {FullProgress} from 'components/LoadingPage'
import { useObserver } from 'mobx-react';
import Axios from "axios";

// import { ROUTE } from 'constants/route';

const PrivateRoute = ({component:Component,...rest}:any) => {
    const {account , websites , setWebsites } = useStores();
    const [websitesLoaded , setWebsitesLoaded] =  useState<boolean>(websites.length > 0 )

    useEffect(() => {
        account.checkUser();
        if(websites.length === 0) loadWebsites()
        return () => {

        }
    },[]);

    async function loadWebsites(){
        try{
            const w = await Axios.request<null, any>({
                method:"GET",
                url: `/website/websites/`
            });
            if(w){
                setWebsites(w.results);
                setWebsitesLoaded(true)
            }
        }catch(e){

        }
    }

    return useObserver(() => {
        if(account.checking || !websitesLoaded) return <FullProgress />
        if(account.isLogged) return <Route component={Component} {...rest} />
        return (
            <Route
                render={ (props:any) => (
                        <Redirect
                            to={{
                            pathname: '/login',
                            state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        )
    })
} 

export default PrivateRoute;