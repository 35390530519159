import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useAlert from 'states/hooks/useAlert'
import useStores from 'states/hooks/useStores';
import { useObserver } from 'mobx-react'

function AppSnackbar() {
    // const {alert} = useStores();
    const { getShow  , getText , getType , dismiss } = useAlert();
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }
        
        dismiss();
    };
    // console.log("show" , alert.showAlert)
    return useObserver(() => (
        <Snackbar open={getShow} autoHideDuration={6000} onClose={handleClose}>
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={getType}>
                {getText}
            </MuiAlert>
        </Snackbar>
    ))
}

export default AppSnackbar;