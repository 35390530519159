import { SnapshotIn } from "mobx-state-tree";
import { FormListModel } from "./Forms";
import { FormInviteModel, QuestionModel , AnswerModel, ManualUser, BroadcastAutosendSessions } from "./Form";
import Website from "./Website";

// form type
export interface IFormListModel extends SnapshotIn<typeof FormListModel> {}
export interface IFormInviteList extends SnapshotIn<typeof FormInviteModel> {}
export interface IFormQuestionListModel extends SnapshotIn<typeof QuestionModel> {}
export interface IFormQuestionAnswerModel extends SnapshotIn<typeof AnswerModel> {}
export interface IFormManualUserModel extends SnapshotIn<typeof ManualUser> {}
export interface IFormAutosendSessionModel extends SnapshotIn<typeof BroadcastAutosendSessions> {}
export interface IWebsiteModel extends SnapshotIn<typeof Website> {}

export enum FormType {
    KnowledgeForm=1,
    SatisfactionSurvey=2
}

export enum AutoSendSessionAssingmentTypes {
    BEFORE_5_DAYS=1,
    END=2,
    BOTH=3
}

export enum QUESTION_TYPE {
    TEXT=1,
    RADIO,
    CHECKNOX
}

export enum InvitationType {
    PRE_TEST=1,
    POST_TEST,
    SATISFACTION
}