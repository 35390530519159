import React from 'react';
import { types } from "mobx-state-tree";
import UserName from "../../utils/userName";
import Axios from "axios";

export const DEFAULT_DASHBOARD_ACCESS_DATA = {
  USERNAME:'admin',
  PASSWORD:'20dash20dop'
}

export const USER_LOCALSTROAGE_TOKEN_KEY = "USER_TOKEN"

// const UserDetails = types.model({
//     name:types.optional(types.string,"")
// }).actions((self) => {
//     function setDetail(newDetails) {
//       self = newDetails
//     }
//
//     return {
//       setDetail
//     }
// });

const Account = types.model({
    name: types.optional(types.string , ""),
    token: types.optional(types.string,""),
    checking:types.optional(types.boolean,true)
}).actions((self) => {

  function setLoggedin(data, token , remember){
      self.name = UserName(data);
      self.token = token;

      if(remember){
        window.localStorage.setItem(USER_LOCALSTROAGE_TOKEN_KEY,token)
      }
  }

  function setLoading(vale){
      self.checking = vale;
  }

  async function checkUser(){
    const token = window.localStorage.getItem(USER_LOCALSTROAGE_TOKEN_KEY);
    try{
        if(token){
            const { REACT_APP_TOKEN_HEADER_NAME , REACT_APP_TOKEN_HEADER_TYPE } = process.env;
            const ch = await Axios.request({
                method:"GET",
                url:`/user/admin/profile/`,
                headers:{
                    [REACT_APP_TOKEN_HEADER_NAME] : `${REACT_APP_TOKEN_HEADER_TYPE} ${token}`
                }
            });
            if(ch){
                this.setLoggedin(ch,token,true);
            }


        }
    }catch(e){

    }
    this.setLoading(false);
  }

  function userLogout(){
    self.token = "";
    window.localStorage.removeItem(USER_LOCALSTROAGE_TOKEN_KEY);
  }

  return {
    userLogout,
    setLoggedin,
    checkUser,
    setLoading
  }
  
}).views((self) => ({

  get isLogged(){
    return self.token ? true : false
  }

}));

export default Account;