import React from 'react'
import { useObserver } from 'mobx-react'
import useStores from './useStores'
function useAlert() {
  const { alert } = useStores();
  // console.log("alert" , alert)
  return useObserver(() => ({
    getShow:alert.show,
    getText:alert.text,
    getType:alert.type,
    setAlert:alert.setAlert,
    dismiss:alert.dismissAlert
  })); 
}


export default useAlert;