import React from 'react';
import { types, applySnapshot, getParent } from "mobx-state-tree";
import moment from 'moment';
import "moment/locale/fr";
import Axios from 'axios';
import { AlertEmit } from 'helpers/Emits';
import { CreationMonthShowFormat, FormStatus } from 'constants/forms';
import { FormType } from './types';
import i18n from "i18next";

moment.locale("fr");

// export const FormListModel = types.model({
//     id:types.integer,
//     name:types.string,
//     type:types.string,
//     questions:types.integer,
//     affected_sessions:types.integer,
//     learner_access:types.integer,
//     answers:types.integer,
//     status:types.string,
//     creation_date:types.Date
// });

export const FormListModel = types.model({
    id:types.optional(types.integer,0),
    private_name:types.optional(types.string,""),
    public_description:types.optional(types.string,""),
    public_name:types.optional(types.string,""),
    form_type:types.optional(
        types.union(
            types.literal(FormType.KnowledgeForm), // Knowledge Form
            types.literal(FormType.SatisfactionSurvey), // Satisfaction Survey
        )
    ,1),
    is_open:types.optional(types.boolean,false),
    teacher_access_to_stats:types.optional(types.boolean,false),
    user_see_stats:types.optional(types.boolean,false),
    num_of_questions:types.optional(types.integer,0),
    num_of_affected_sessions:types.optional(types.integer,0),
    num_of_learners:types.optional(types.integer,0),
    num_of_answers:types.optional(types.integer,0),
    created_at:types.optional(types.string,""),
    modified_at:types.optional(types.string,""),
    archvied:types.optional(types.boolean,false),
    open_close_loading:types.optional(types.boolean,false),
}).actions((self) => {
    function setDetails(data) {
        self = data
    }

    function setPrivateName(val) {
        self.private_name = val;
    }

    function setFormType(val) {
        self.form_type = val;
    }

    function setDescription(val){
        self.public_description = val;
    }

    function setPulicName(val){
        self.public_name = val;
    }

    async function toggleIsOpen(){
        if(self.open_close_loading) return;

        this.setOpenCloseLoading(true)
        let response = await Axios.request({
            method:"PATCH",
            url: `/dashboard/forms/${self.id}/`,
            data:{
                is_open: !self.is_open
            }
        });
        if(response){
            AlertEmit(i18n.t(self.is_open ? "opened" : "closed"),"success")
        }
        this.setIsOpen(!self.is_open);
        this.setOpenCloseLoading(false);

        // reload forms in form list
        getParent(self,2).forms.loadForms()
    }

    function setIsOpen(val){
        self.is_open = val;
    }

    function setOpenCloseLoading(val){
        self.open_close_loading = val;
    }

    return {
        setDetails,
        setPrivateName,
        setDescription,
        setPulicName,
        toggleIsOpen,
        setIsOpen,
        setOpenCloseLoading,
        setFormType
    }
});

const Forms = types.model({
    creationMonth:types.optional(types.string,"All"),
    company:types.optional(types.string , "All"),
    status:types.optional(
        types.union(
            types.literal(FormStatus.ALL),
            types.literal(FormStatus.OPEN),
            types.literal(FormStatus.CLOSE),
        )    
    ,FormStatus.ALL),
    results:types.optional(types.array(FormListModel),[]),
    loading:types.optional(types.boolean,true)
}).actions((self) => {

    function setMonth(date) {
      self.creationMonth = date;
      self.loadForms();
    }

    function setCompany(company){
        self.company = company;
        self.loadForms();
    }

    function setStatus(status){
        self.status = status;
        self.loadForms();
    }

    function addList(details){
        self.results.push(details);
    }

    function setList(data){
        applySnapshot(self.results, data);
    }

    function clearList(){
        applySnapshot(self.results , []);
    }

    function setLoading(load){
        self.loading = load;
    }

    async function loadForms(){
        self.clearList();
        self.setLoading(true);

        const creationsDateFilterMomentObject = moment(self.creationMonth,CreationMonthShowFormat)
        const dateParams = {};
        
        if(self.creationMonth !== "All") {
            dateParams.created_at__date__gte = creationsDateFilterMomentObject.startOf('month').format("YYYY-MM-DD");
            dateParams.created_at__date__lte =creationsDateFilterMomentObject.endOf('month').format("YYYY-MM-DD");
        };

        if(self.company !== 'All') {
            dateParams.website = self.company;
        }

        try{
            let response = await Axios.request({
                method:"GET",
                url: "/dashboard/forms/",
                params:{
                    is_open:self.status,
                    ...dateParams
                }
            });
            if(response){
                self.setList(response.results)
            }
        }catch(e){
            console.log("error" , e)
        }

        self.setLoading(false);
    }

    return {
        setMonth,
        setCompany,
        setStatus,
        addList,
        setLoading,
        clearList,
        setList,
        loadForms
    }
}).views((self) => ({
    get getResults(){
        return self.results;
    }
}));


export default Forms;