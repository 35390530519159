interface RouteType {
    [key:string]:{
        link:string,
        useLink?:string,
        title?:string
        back?:string
    }
}

export const ROUTE:RouteType = {
    MAIN:{
        link:'/',
        title:''
    },
    LOGIN:{
        link:'/login'
    },
    FORGOT:{
        link:'/forgot'
    },
    FORMS:{
        link:'/forms',
        back:'/',
        title:'formsTitle'
    },
    FORM:{
        link:'/form/:id',
        useLink:'/form/',
        back:'/forms',
        title:'formTitle'
    },
    BANNERS:{
        link:'/banners',
        title:'bannerSliderTitle',
        back:'/',
    },
    TEAM_MEMBERS:{
        link:'/teammember',
        title:'teamMembersList',
        back:'/',
    },
    ARTICLES:{
        link:'/articles',
        title:'articles',
        back:'/'
    },
    CATEGORY_IMAGES:{
        link:'/categoryimages',
        title:'categoryImages',
        back:'/'
    },
    SESSIONS:{
        link:'/sessions',
        title:'sessions',
        back:'/'
    },
    PARTNERS:{
        link:'/partners',
        title:'partners',
        back:'/'
    },
    TEXTS:{
        link:'/texts',
        title:'texts',
        back:'/'
    }
}